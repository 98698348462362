import Splide from "@splidejs/splide";

// Services slider
if (document.querySelector('.services-slider')) {

    if (window.innerWidth <= 640)
        intializeServiceSlider();

    window.addEventListener('resize', function () {
        if (window.innerWidth <= 640) {
            intializeServiceSlider(true);
        } else {
            intializeServiceSlider(true, true);
        }

    });

}

function intializeServiceSlider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('services-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const servicesSlider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            breakpoints: {
                640: {
                    destroy: false,
                },
            },
        });
        servicesSlider.mount();
        if (true === resize)
            servicesSlider.refresh();
        if (true === destroy)
            servicesSlider.destroy();

    }
}