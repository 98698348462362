import Splide from "@splidejs/splide";

// Testimonial List slider
if (document.querySelector('.testimonial-slider')) {
    var sliderselector = document.getElementsByClassName('testimonial-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const servicesSlider = new Splide(slide_ele, {
            type: "loop",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            autoplay: true,
            interval: 5000,
        });
        servicesSlider.mount();
    }
}

document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector(".listing-testimonial__video") != null) {
        let links = document.querySelectorAll(".testimonial_video_link");
        links.forEach(function (link) {
            link.addEventListener("click", function (e) {
                e.preventDefault();
                var mediaurl = this.dataset.mediaurl;
                if (document.querySelector(".lightbox-popup") != null) {
                    var parent = document.querySelector(".lightbox-popup");
                    var iframe = parent.querySelector(".lightbox-popup__video");
                    if (iframe.src.indexOf("/" + mediaurl + "/?showinfo=0") < 0) {
                        iframe.src = mediaurl;
                    }
                    parent.classList.add("lightbox-popup--opened");
                }
            });
        });
        if (document.querySelector(".lightbox-popup__close") != null) {
            let button = document.querySelector(".lightbox-popup__close");
            button.addEventListener("click", function () {
                var close_btn_parent = this.parentElement.parentElement.parentElement;
                close_btn_parent.getElementsByTagName("video")[0].src = "";
                close_btn_parent.classList.remove("lightbox-popup--opened");
            });
        }
    }
});
