document.addEventListener('DOMContentLoaded', function () {
    var endElement = document.querySelector(".count--endDate");

    if (!endElement) {
        return;
    }

    var count_endDate = new Date(endElement.textContent.trim()).getTime();

    if (isNaN(count_endDate)) {
        console.error("Invalid end date");
        return;
    }

    var countdownInterval = setInterval(function () {
        var now = new Date().getTime();
        var distance = count_endDate - now;

        if (distance < 0) {
            clearInterval(countdownInterval);
            document.getElementById("countdown-timer").innerHTML = "EXPIRED";
            return;
        }

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.querySelector(".countdown-days").textContent = days;
        document.querySelector(".countdown-hours").textContent = hours;
        document.querySelector(".countdown-minutes").textContent = minutes;
        document.querySelector(".countdown-seconds").textContent = seconds;

    }, 1000);
});
