const header = document.querySelector('header.header--main');

window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
        header.classList.add('header-striky');
    } else {
        header.classList.remove('header-striky');
    }
});
