import Splide from "@splidejs/splide";

// Customer Reviews Card slider
if (document.querySelector('.customer-reviews-slider')) {
    window.addEventListener('load', function () {
        var sliderselector = document.getElementsByClassName('customer-reviews-slider');
        for (var i = 0; i < sliderselector.length; i++) {
            const slide_ele = sliderselector[i];
            const case_studies_Slider = new Splide(slide_ele, {
                type: "loop",
                arrows: false,
                perPage: 1,
                perMove: 1,
                gap: 7,
                pagination: false,
                autoHeight: true,
                height: "auto",
                autoplay: true,
                interval: 3000,
            });
            case_studies_Slider.mount();
        }
    });
}