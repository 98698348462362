import Splide from "@splidejs/splide";

if (document.querySelector('.case-studies-slider')) {
    if (window.innerWidth <= 768) {
        initializeCaseSlider();
    }
    window.addEventListener('resize', function () {
        if (window.innerWidth <= 768) {
            initializeCaseSlider(true);
        }
    });
}

function initializeCaseSlider(resize = false) {
    var sliderselector = document.getElementsByClassName('case-studies-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const case_studies_Slider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            autoHeight: true,
            height: "auto"
        });
        case_studies_Slider.mount();

        if (true === resize)
            case_studies_Slider.refresh();
    }
}