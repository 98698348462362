import Splide from '@splidejs/splide';

function initializereviewSlider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('custom-review-content');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const reviews_Slider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true
        });
        reviews_Slider.mount();
        if (resize === true)
            reviews_Slider.refresh();
        if (destroy === true)
            reviews_Slider.destroy();
    }
}

document.addEventListener("DOMContentLoaded", function () {
    initializereviewSlider(true);

    window.addEventListener('resize', function () {
        initializereviewSlider(true);
    });

});
