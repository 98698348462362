import Splide from "@splidejs/splide";

// Case Studies slider
function adjustElementHeights(selector) {
    var selectorelement = document.querySelectorAll(selector);

    var maxHeight = 0;
    // Loop through elements of second selector and set height
    for (var i = 0; i < selectorelement.length; i++) {
        maxHeight = Math.max(maxHeight, selectorelement[i].offsetHeight);
    }
    selectorelement.forEach(function (element) {
        if (maxHeight != 0)
            element.style.height = maxHeight + 'px'; // Set height of inner element
    });
}


if (document.querySelector('.solution--slider')) {
    if (window.innerWidth <= 768) {
        initializeCaseSlider();
        adjustElementHeights('.solution--slider .solution__wrapper .solution--body');
        adjustElementHeights('.solution--slider .solution__wrapper .solution--header .solution--name');
        adjustElementHeights('.solution--slider .solution__wrapper .solution_img_box');
    }
    window.addEventListener('resize', function () {
        if (window.innerWidth <= 768) {
            initializeCaseSlider(true);
            document.querySelectorAll('.solution--slider .solution__wrapper .solution--body').forEach(function (element) {
                element.style.height = 'auto';
            });
            document.querySelectorAll('.solution--slider .solution__wrapper .solution--header .solution--name').forEach(function (element) {
                element.style.height = 'auto';
            });
            document.querySelectorAll('.solution--slider .solution__wrapper .solution_img_box').forEach(function (element) {
                element.style.height = 'auto';
            });
            adjustElementHeights('.solution--slider .solution__wrapper .solution_img_box');
            adjustElementHeights('.solution--slider .solution__wrapper .solution--body');
            adjustElementHeights('.solution--slider .solution__wrapper .solution--header .solution--name');
        } else {
            document.querySelectorAll('.solution--slider .solution__wrapper .solution--body').forEach(function (element) {
                element.style.height = 'auto';
            });
            document.querySelectorAll('.solution--slider .solution__wrapper .solution--header .solution--name').forEach(function (element) {
                element.style.height = 'auto';
            });
            document.querySelectorAll('.solution--slider .solution__wrapper .solution_img_box').forEach(function (element) {
                element.style.height = 'auto';
            });
        }
    });
}

function initializeCaseSlider(resize = false) {
    var sliderselector = document.getElementsByClassName('solution--slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const solution_Slider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            drag: false,
            pagination: true,
            autoHeight: true,
            height: "auto",
            breakpoints: {
                768: {
                    gap: 10,
                    drag: true
                },
            },
        });
        solution_Slider.mount();

        if (true === resize)
            solution_Slider.refresh();
    }
}