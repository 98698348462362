import Splide from '@splidejs/splide';

function initializeTabSlider(resize = false, destroy = false) {
  var sliderselector = document.getElementsByClassName('tabs-content');

  for (var i = 0; i < sliderselector.length; i++) {
    const slide_ele = sliderselector[i];
    const tabs_Slider = new Splide(slide_ele, {
      type: "slide",
      arrows: false,
      perPage: 1,
      perMove: 1,
      gap: 0,
      pagination: true,
      drag: false,
      breakpoints: {
        767: {
          drag: true
        }
      }
    });
    tabs_Slider.mount();
    if (resize === true)
      tabs_Slider.refresh();
    if (destroy === true)
      tabs_Slider.destroy();
  }
}

document.addEventListener("DOMContentLoaded", function () {
  if (window.innerWidth < 767) {
    initializeTabSlider(true);
  }

  window.addEventListener('resize', function () {
    if (window.innerWidth < 767) {
      initializeTabSlider(true);
    }
  });

  if (document.querySelector(".tabs")) {
    let lists = document.querySelectorAll(".tabs");

    lists.forEach((list, index) => {
      let listItem = list.querySelector(".tabs-nav__item");
      listItem.classList.add("tabs-nav__item--active");

      let listItemContent = listItem.dataset.content;
      document
        .querySelector("." + listItemContent)
        .classList.add("tabs-content__item--active");

      let listItemAll = list.querySelectorAll(".tabs-nav__item");
      listItemAll.forEach((item, index) => {
        item.addEventListener("click", function (e) {
          e.preventDefault();

          if (
            !item.classList.contains("tabs-nav__item--active") &&
            item.dataset.content
          ) {
            var currentItem = item.parentElement.querySelector(
              ".tabs-nav__item--active"
            );
            if (currentItem) {
              var currentItemContent = currentItem.dataset.content;
              currentItem.classList.remove("tabs-nav__item--active");

              var currentItemContentElement = document.querySelector("." + currentItemContent);
              if (currentItemContentElement) {
                currentItemContentElement.classList.remove("tabs-content__item--active");
              }
            }

            var newItemContent = item.dataset.content;
            item.classList.add("tabs-nav__item--active");
            document
              .querySelector("." + newItemContent)
              .classList.add("tabs-content__item--active");
          }
        });
      });
    });
  }
});
