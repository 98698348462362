import Splide from "@splidejs/splide";

// founders slider
if (document.querySelector('.founder-slider')) {
    if (window.innerWidth >= 1300) {
        document.querySelector('.founder-slider').classList.add('slider-not-init');
        document.querySelector('.founder-slider').classList.remove('is-initialized');
        splideslidelength();
        initializeFounderSlider(false, true);
    } else {
        document.querySelector('.founder-slider').classList.remove('slider-not-init');
        initializeFounderSlider();
        if (document.querySelector('.founder-slider').classList.contains('founder-two-slide')) {
            document.querySelector('.founder-slider').classList.remove('founder-two-slide');
        }
        if (document.querySelector('.founder-slider').classList.contains('founder-three-slide')) {
            document.querySelector('.founder-slider').classList.remove('founder-three-slide');
        }
    }

    window.addEventListener('resize', function () {
        if (window.innerWidth >= 1300) {
            document.querySelector('.founder-slider').classList.add('slider-not-init');
            document.querySelector('.founder-slider').classList.remove('is-initialized');
            initializeFounderSlider(false, true);
            splideslidelength();
        } else if (window.innerWidth <= 1300) {
            document.querySelector('.founder-slider').classList.remove('slider-not-init');
            if (document.querySelector('.founder-slider').classList.contains('founder-two-slide')) {
                document.querySelector('.founder-slider').classList.remove('founder-two-slide');
            }
            if (document.querySelector('.founder-slider').classList.contains('founder-three-slide')) {
                document.querySelector('.founder-slider').classList.remove('founder-three-slide');
            }
            initializeFounderSlider(true);
        }
    });
}
function splideslidelength() {
    var splide__slide_length = document.querySelectorAll('.founder-slider .splide__slide').length;
    if (splide__slide_length == 2) {
        document.querySelector('.founder-slider').classList.add('founder-two-slide');
    } else if (splide__slide_length == 3) {
        document.querySelector('.founder-slider').classList.add('founder-three-slide');
    }
}

function initializeFounderSlider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('founder-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const founders_Slider = new Splide(slide_ele, {
            type: "loop",
            arrows: false,
            perPage: 2,
            perMove: 1,
            gap: 30,
            pagination: true,
            autoplay: true,
            interval: 5000,
            breakpoints: {
                991: {
                    perPage: 1,
                    gap: 0,
                    destroy: false,
                },
            },
        });
        founders_Slider.mount();
        if (true === resize)
            founders_Slider.refresh();
        if (true === destroy)
            founders_Slider.destroy();

    }
}