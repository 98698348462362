window.addEventListener('DOMContentLoaded', function () {
    var headlineElements = document.querySelectorAll('#headline_brakets');

    headlineElements.forEach(function (titleElement) {
        var titleText = titleElement.innerText;
        var newText = '';
        var isInBracket = false;

        for (var i = 0; i < titleText.length; i++) {
            if (titleText[i] === '[') {
                isInBracket = true;
                newText += '<span class="orange-bracket">[';
            } else if (titleText[i] === ']') {
                isInBracket = false;
                newText += ']</span>';
            } else if (titleText[i] === '&') {
                isInBracket = false;
                newText += '<span class="orange-bracket">&</span>';
            } else {
                newText += titleText[i];
            }
        }

        titleElement.innerHTML = newText;
    });
});
