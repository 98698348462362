import Splide from "@splidejs/splide";

// WorkProgress slider
if (document.querySelector('.work-progress--slider')) {

    if (window.innerWidth <= 767)
        intializeWorkProgresslider();

    window.addEventListener('resize', function () {
        if (window.innerWidth <= 767) {
            intializeWorkProgresslider(true);
        } else {
            intializeWorkProgresslider(true, true);
        }

    });

}

function intializeWorkProgresslider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('work-progress--slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const WorkProgressSlider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            breakpoints: {
                640: {
                    destroy: false,
                },
            },
        });
        WorkProgressSlider.mount();
        if (true === resize)
            WorkProgressSlider.refresh();
        if (true === destroy)
            WorkProgressSlider.destroy();

    }
}