document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".accordion") != null) {
    let accordions = document.querySelectorAll(".accordion");

    accordions.forEach((acc) => {
      let accordionItems = acc.querySelectorAll(".accordion-item");

      accordionItems.forEach((item) => {
        item.firstChild.addEventListener("click", function (e) {
          e.preventDefault();

          item.classList.toggle("accordion-item--active");
          if (item.classList.contains("accordion-item--active")) {
            item.children[1].style.maxHeight = item.children[1].scrollHeight + "px";
          } else {
            item.children[1].style.maxHeight = null;
          }

          accordionItems.forEach((accItem) => {
            if (accItem !== item) {
              accItem.classList.remove("accordion-item--active");
              accItem.children[1].style.maxHeight = null;
            }
          });

        });
      });

    });

  }
});