import Splide from "@splidejs/splide";

// Testimonial Box slider
if (document.querySelector('.testimonial-box-slider')) {
    var sliderselector = document.getElementsByClassName('testimonial-box-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const testimonial_box_Slider = new Splide(slide_ele, {
            type: "loop",
            arrows: false,
            perPage: 2,
            perMove: 1,
            gap: 22,
            pagination: true,
            autoplay: true,
            interval: 5000,
            breakpoints: {
                768: {
                    perPage: 1,
                },
            },
        });
        testimonial_box_Slider.mount();
    }
}

if (document.querySelector('.testimonial-two-box-slider')) {
    var sliderselector = document.getElementsByClassName('testimonial-two-box-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const testi_two_box_Slider = new Splide(slide_ele, {
            type: "loop",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 22,
            pagination: true,
            autoplay: true,
            interval: 5000
        });
        testi_two_box_Slider.mount();
    }
}
