if (document.querySelector('.masonry-grid')) {
  window.onload = function () {
    var allImages = [];

    document.querySelectorAll(".masonry-grid img").forEach(function (img) {
      allImages.push({
        src: img.src,
        alt: img.alt
      });

      img.addEventListener("click", function () {
        document.querySelector(".gallary-lightbox").style.display = "flex";
        document.querySelector(".gallary-lightbox").closest('.section__content').style.position = "unset";
        var newImg = document.createElement("img");
        newImg.src = this.src;
        newImg.alt = this.alt;
        document.querySelector(".gallary-lightbox").appendChild(newImg);
        document.querySelector(".filter").style.backgroundImage = "url(" + this.src + ")";
        document.documentElement.style.overflow = "hidden";
        var images = Array.from(document.querySelectorAll(".masonry-grid img"));
        var index = images.findIndex(item => item.src === this.src);
        if (this === this.parentNode.lastElementChild) {
          document.querySelector(".arrowr").style.display = "none";
          document.querySelector(".arrowl").style.display = "block";
        } else if (this === this.parentNode.firstElementChild) {
          document.querySelector(".arrowr").style.display = "block";
          document.querySelector(".arrowl").style.display = "none";
        } else {
          document.querySelector(".arrowr").style.display = "block";
          document.querySelector(".arrowl").style.display = "block";
        }
      });
    });

    document.querySelector(".close").addEventListener("click", function () {
      document.querySelector(".gallary-lightbox").style.display = "none";
      document.querySelector(".gallary-lightbox").closest('.section__content').style.position = "relative";
      document.querySelector(".gallary-lightbox img").remove();
      document.documentElement.style.overflow = "auto";
    });

    document.addEventListener("keyup", function (e) {
      if (e.keyCode === 27) {
        document.querySelector(".gallary-lightbox").style.display = "none";
        document.querySelector(".gallary-lightbox img").remove();
        document.documentElement.style.overflow = "auto";
      }
    });

    document.querySelector(".arrowr").addEventListener("click", function () {
      var imgSrc = document.querySelector(".gallary-lightbox img").src;
      var search = Array.from(document.querySelectorAll(".masonry-grid img")).find(img => img.src === imgSrc);
      var newImage = search.nextElementSibling ? search.nextElementSibling.src : null;
      if (newImage) {
        document.querySelector(".gallary-lightbox img").src = newImage;
        document.querySelector(".filter").style.backgroundImage = "url(" + newImage + ")";
        document.querySelector(".arrowl").style.display = "block";
      }
      if (!search.nextElementSibling.nextElementSibling) {
        document.querySelector(".arrowr").style.display = "none";
      }
    });

    document.querySelector(".arrowl").addEventListener("click", function () {
      var imgSrc = document.querySelector(".gallary-lightbox img").src;
      var search = Array.from(document.querySelectorAll(".masonry-grid img")).find(img => img.src === imgSrc);
      var newImage = search.previousElementSibling ? search.previousElementSibling.src : null;
      if (newImage) {
        document.querySelector(".gallary-lightbox img").src = newImage;
        document.querySelector(".filter").style.backgroundImage = "url(" + newImage + ")";
        document.querySelector(".arrowr").style.display = "block";
      }
      if (!search.previousElementSibling.previousElementSibling) {
        document.querySelector(".arrowl").style.display = "none";
      }
    });
  };

}