import Splide from '@splidejs/splide';

function initializeimageSlider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('custom-image-content');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];

        const images_Slider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 4,
            perMove: 1,
            gap: 30,
            pagination: true,
            autoplay: true,
            interval: 5000,
            breakpoints: {
                1299: {
                    perPage: 3,
                    drag: true
                },
                767: {
                    perPage: 2,
                    drag: true,
                    gap: 10
                }
            }
        });

        images_Slider.mount();
        if (resize === true)
            images_Slider.refresh();
        if (destroy === true)
            images_Slider.destroy();
    }
}

document.addEventListener("DOMContentLoaded", function () {
    initializeimageSlider(true);

    window.addEventListener('resize', function () {
        initializeimageSlider(true);
    });
});
