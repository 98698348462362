import Splide from "@splidejs/splide";

// wordpress cards slider
function adjustElementHeights(selector) {
    var selectorelement = document.querySelectorAll(selector);

    selectorelement.forEach(function (element) {
        element.style.height = 'auto';
    });

    var maxHeight = 0;
    selectorelement.forEach(function (element) {
        maxHeight = Math.max(maxHeight, element.offsetHeight);
    });

    selectorelement.forEach(function (element) {
        if (maxHeight !== 0) {
            element.style.height = maxHeight + 'px';
        }
    });
}


if (document.querySelector('.wordpress-cards-slider')) {
    adjustElementHeights('.wordpress-cards-slider .wp-card_wrapper .wp-card-image');
    if (window.innerWidth <= 640) {
        initializeCaseSlider();
    }
    window.addEventListener('resize', function () {
        if (window.innerWidth <= 640) {
            initializeCaseSlider(true);
            adjustElementHeights('.wordpress-cards-slider .wp-card_wrapper .wp-card-image');
        } else {
            adjustElementHeights('.wordpress-cards-slider .wp-card_wrapper .wp-card-image');
        }
    });
}

function initializeCaseSlider(resize = false) {
    var sliderselector = document.getElementsByClassName('wordpress-cards-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const wordpress_Slider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            drag: false,
            pagination: true,
            autoHeight: true,
            height: "auto",
            breakpoints: {
                640: {
                    drag: true,
                },
            },
        });
        wordpress_Slider.mount();

        if (true === resize)
            wordpress_Slider.refresh();
    }
}