
function logHeaderHeight() {
    const headerMain = document.querySelector('.header.header--main');
    const gutentocOllist = document.querySelector('.gutentoc.ollist');
    const BlogDetailRightWrap = document.querySelector('.blog-detail-right-wrap');
    if (headerMain) {
        const headerHeight = headerMain.offsetHeight;
        if (gutentocOllist) {
            gutentocOllist.style.top = `${headerHeight + 20}px`;
        }
        if (BlogDetailRightWrap) {
            BlogDetailRightWrap.style.top = `${headerHeight + 20}px`;
        }
    }
}

window.addEventListener('load', logHeaderHeight);
window.addEventListener('scroll', logHeaderHeight);
window.addEventListener('resize', logHeaderHeight);