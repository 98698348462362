import Splide from "@splidejs/splide";

// team sliders
const sliders = document.querySelectorAll('.team-slider');

sliders.forEach(slider => {
    initializeTeamSlider(slider);

    window.addEventListener('resize', function () {
        initializeTeamSlider(slider, true);
    });
});
function initializeTeamSlider(slider, resize = false) {
    const teamSlider = new Splide(slider, {
        type: "slide",
        arrows: false,
        perPage: 6,
        perMove: 1,
        gap: 7,
        pagination: true,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1199: {
                perPage: 5,
            },
            1024: {
                perPage: 4,
            },
            769: {
                perPage: 3,
            },
            576: {
                perPage: 2,
            },
            420: {
                perPage: 1,
            },
        },
    });

    teamSlider.on('mounted', function () {
        if (!slider.classList.contains('is-overflow')) {
            slider.querySelector('.splide__list').classList.add('justify-center');
        } else {
            slider.querySelector('.splide__list').classList.remove('justify-center');
        }
    });
    teamSlider.mount();
    if (resize === true) {
        teamSlider.refresh();
        if (!slider.classList.contains('is-overflow')) {
            slider.querySelector('.splide__list').classList.add('justify-center');
        } else {
            slider.querySelector('.splide__list').classList.remove('justify-center');
        }
    }
}