import Splide from "@splidejs/splide";

// number slider
if (document.querySelector('.number--slider')) {

    if (window.innerWidth <= 991)
        intializenumberlider();

    window.addEventListener('resize', function () {
        if (window.innerWidth <= 991) {
            intializenumberlider(true);
        } else {
            intializenumberlider(true, true);
        }

    });

}

function intializenumberlider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('number--slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const numberSlider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            breakpoints: {
                991: {
                    destroy: false,
                },
            },
        });
        numberSlider.mount();
        if (true === resize)
            numberSlider.refresh();
        if (true === destroy) {
            numberSlider.destroy();
        }

    }
}